








































































































































































































































.container {
    // background-color: #f8f8f8;
	font-family: PingFangSC-Regular;
    // height: calc(100vh + 110px);
    .content {
        // background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #ffffff;
        font-size: 14px;
    }
}
.user_msg {
    background-color: #ffffff;
    margin: 10px;
    border-radius: 8px;
    // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
    font-family: PingFang SC;
    .label_input {
        margin: 0 0 0 0.2rem;
        padding: 0.39rem 0;
        display: flex;
        align-items: center;
        .label {
			font-family: PingFangSC-Medium;
            color: #333333;
            font-size: 0.28rem;
            text-align: left;
            font-weight: 550;
        }
		input::-webkit-input-placeholder {
		    /* 修改字体颜色 */
		    color: #999999;
		    /* 修改字号，默认继承input */
		    font-size: 12px;
		}
        .input {
            margin-left: 0.32rem;
            input {
                padding-right: 1rem;
                width: 100%;
                color: #333;
				font-size: 0.28rem;
                border: none;
            }
        }
    }
    .xiala {
        margin: 0 0.2rem;
        padding: 0.3rem 0;
        border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
        display: flex;
        justify-content: space-between;
        .label {
			font-family: PingFangSC-Medium;
            font-size: 0.3rem;
            color: #333333;
            font-weight: 550;
            text-align: left;
        }
        .label1 {
			font-family: PingFangSC-Medium;
            // margin-right: 3rem;
            font-size: 0.3rem;
            color: #333333;
            font-weight: 500;
            text-align: left;
        }
    }
    .xiala_content {
        margin: 0 0.2rem;
        font-size: 0.3rem;

        .option_select {
            border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
            padding: 0.2rem 0;
            display: flex;
            font-size: 0.28rem;
            justify-content: center;
        }
        .option_select:last-child {
            border-bottom: none;
        }
    }
    .label_input1 {
        margin: 0 0 0 0.16rem;
        padding: 0.28rem 0;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        border-bottom: 0.5px solid rgba(211, 217, 222, 0.5);
        .label {
			font-family: PingFangSC-Medium;
            color: #333333;
            font-size: 0.28rem;
            text-align: left;
            font-weight: 550;
        }
		input::-webkit-input-placeholder {
		    /* 修改字体颜色 */
		    color: #999999;
		    /* 修改字号，默认继承input */
		    font-size: 12px;
		}
        .input {
            margin-left: 0.22rem;
            input {
				font-size: .28rem;
                text-align: left;
                padding-right: 0.2rem;
                width: 100%;
                color: #333;
                border: none;
            }
        }
    }
}
.user_msg:last-child {
    padding-bottom: 0.4rem;
}
.bottom {
    position: fixed;
    left: 0;
    bottom: 0.2rem;
    padding: 0;
    .button {
        margin: 0.14rem 0.3rem 0 0.28rem;
        width: 6.7rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
        color: #FFFFFF;
        font-size: 0.32rem;
        text-align: center;
        border-radius: 0.12rem;
        font-weight: 500;
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;